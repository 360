import Header from '../components/header'
import Footer from '../components/footer'
import './layout.css'
import { useLocation } from "react-router-dom";

export default function Layout({ children }) {
  const { pathname } = useLocation();
  return (
    <>
      <Header />
      <main className={pathname === "/" ? 'main_wrapper mainHomepage_wrapper' : 'main_wrapper' }>
        {children}
      </main>
      <Footer />
    </>
  )
}