import React from "react"
import '../components/serviceHomeCard.css'

const ServiceHomeCard = props => {
    return (
        <div className="cardbox">
            <div className="card">
                <div className="cardBody">
                    <h5 className="cardTitle">{props.title}</h5>
                    <p className="cardText">{props.desc}</p>
                </div>
            </div>
        </div>
    )
}

export default ServiceHomeCard