import React from "react"
import './serviceCard.css'

const ServiceCard = props => {
    return (
        <div className="scardbox">
            <div className="scard">
                <span className="scardIcon">
                    <img src={props.src} alt={props.title} width={96} height={96} />
                </span>
                <h5 className="scardTitle">{props.title}</h5>
                <p className="scardText">{props.desc}</p>
            </div>
        </div>
    )
}

export default ServiceCard