import React from 'react'
import '../modal/modal.css';

const Modal = props => {
    return (
        <div className="modal-wrapper">
            <div className="modal-main-wrap">
                <div className="modal-heading">
                    {props.onClose && (
                        <div 
                            className="modal-close"
                            onClick={props.onClose}
                        >
                            x
                        </div>
                    )}
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal
