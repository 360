import React from "react"
import "./portfolioCard.css"

const PortfolioCard = props => {
    return (
        <div className="portfolioCard">
            <figure>
                <div><img src={props.image} alt={props.title} className="img-fluid" /></div>
                <figcaption>
                    <h3>{props.title}</h3>
                    <span style={{marginRight: '0.5rem'}}>{props.desc}</span>
                    <a href={props.buttonLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        more details
                    </a>
                </figcaption>
            </figure>
        </div>
    )
}

export default PortfolioCard

PortfolioCard.defaultProps = {
    title: "Lorem ipsum",
    image: "/aboutus.svg",
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    buttonLink: "www.example.com",
}