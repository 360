import React from 'react'
import '../components/hireCard.css'

const HireCard = props => {
    return (
        <div className="hire-group">
            {props.listContent.map(function(d, idx){
                return (
                    <div className="hire-card" key={idx}>
                        <div className="hire-main">
                            <div className="hire-image">
                                <img src={d.image} alt={d.title} className="img-fluid" />
                            </div>
                            <div className="hire-content">
                                <span className="hire-subtitle">{d.subtitle}</span>
                                <h3 className="hire-title">{d.title}</h3>
                                <p className="hire-desc">{d.content}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HireCard