import React from "react"
import '../components/mapEmbed.css'

const MapEmbed = props => {
    return (
        <div className="map_box">
          <iframe src={props.src} title="Mahasos" width={props.width} height={props.height} frameBorder="0" style={{border:0}} allowFullScreen></iframe>
        </div>
    )
}

export default MapEmbed
