import React from "react"
import './jobCard.css'
import { NavLink } from "react-router-dom"

const JobCard = props => {
    return (
        <div className="jobbox">
            <div className="jobcard">
                <div className="job_top">
                    <h2>{props.title} <span className="jobcode_text">{props.code}</span></h2>
                    <div className="job_content">
                        {props.jobContent}
                    </div>
                </div>
                <NavLink
                    to="#"
                    className="btn" 
                    onClick={(e) => {
                      window.location.href = 'mailto:hr@mahasos.com?subject=' + props.code;
                      e.preventDefault();
                    }}
                >
                    {props.code}
                </NavLink>
            </div>
        </div>
    )
}

export default JobCard