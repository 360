import '../components/navbar.css'
import { Link } from "react-router-dom";
import Logoimg from '../assets/images/logo.png';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import MenuNav from './menuNav';


export default function NavBar() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [ isOpenMenu, setIsOpenMenu] = useState(false);
    const menuToggle = () => {
        setIsOpenMenu(state => !state);
    }
    return (
        <>
            <div className="nav_wrapper">
                <div className="container">
                    <div className="nav_main">
                        <div className="logo">
                            <Link to="/"><img src={Logoimg} alt="MahaSOS Logo" className="img-fluid" /></Link>
                        </div>
                        {isTabletOrMobile && (
                            <div 
                                className="menu-toggle"
                                onClick={menuToggle}
                            >
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="24" 
                                    height="24" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    stroke="currentColor" 
                                    strokeWidth="2" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round"
                                >
                                    {isOpenMenu ? <>
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </>
                                    :
                                    <>
                                        <line x1="3" y1="12" x2="21" y2="12"></line>
                                        <line x1="3" y1="6" x2="21" y2="6"></line>
                                        <line x1="3" y1="18" x2="21" y2="18"></line>
                                    </>
                                    }
                                </svg>
                            </div>
                        )}
                        {isTabletOrMobile ? 
                            isOpenMenu && <MenuNav /> 
                            :
                            !isOpenMenu && <MenuNav />
                        }
                    </div>
                </div>
            </div>
        </>
  )
}