import React from 'react'
import Layout from '../components/layout'
import HireTeamImg from '../assets/images/hire-team.svg'
import { useMediaQuery } from 'react-responsive';
import DedicatedDevTeamImg from '../assets/images/dedicated-dev-team-img.svg'
import StaffAugmentationImg from '../assets/images/staff-augmentation-img.svg'
import ProjectOutsourceImg from '../assets/images/project-outsource-img.svg'
import HireCard from '../components/hireCard';

const HireOurTeam = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    return (
        <Layout>
            <section className="about_section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className={isMobile ? 'w_12' : 'w_6'}>
                            <h1 className="page_title">Hire Our Team</h1>
                            <p>Our dedicated team of expert developers can help scale up your business and take projects to the next level.</p> 
                            <p>Mahasos Info Private Ltd ® essentially works as an HR arm of your company and provides Software Development services be it through Staff Augmentation or Offshore Software, our team of dedicated professionals are always focused on offering services that are best in class, best in customer experience and suited for your budget.</p>
                            <p>Be it a dedicated team or a dedicated individual you can count on us to make the difference that your business needs to scale up and reach new heights.</p>
                        </div>
                        <div className={isMobile ? 'w_12' : 'w_6'}>
                            <img src={HireTeamImg} alt="MahaSOS Logo" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section bg_secondary">
                <div className="container">
                    <HireCard 
                        listContent={[
                            {
                                subtitle: "Hire a",
                                title: "Dedicated Dev Team",
                                content: "Full time dedicated set of resources for large projects to take on evolutionary enhancements quickly.",
                                image:DedicatedDevTeamImg,
                            },
                            {
                                subtitle: "Hire Individual Resources",
                                title: "Staff Augmentation",
                                content: "Individual dedicated resource(s) for remote team augmentation, to be managed by your team day to day.",
                                image:StaffAugmentationImg,
                            },
                            {
                                subtitle: "Get Your Projects Developed",
                                title: "Project Outsource",
                                content: "Entire team & project managed by us with pre-defined scope and milestones based payment plan.",
                                image:ProjectOutsourceImg,
                            },
                        ]}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default HireOurTeam