import React from 'react'
import Buttons from './button'
import { useNavigate } from 'react-router-dom';

const JoinOurTeamSection = props => {
    const navigate = useNavigate();
    return (
        <section className={`joinourteam-section ${props.className ? props.className : ''}`}>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <div className="container">
                    <h1 className="textCenter mb4">Want to jump on board and make some magic?</h1>
                    <Buttons
                        type="button"
                        title="Join Our Team"
                        white
                        onClick={() => navigate('/team')}
                    />
                </div>
            </div>
        </section>
    )
}

export default JoinOurTeamSection