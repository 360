import React from 'react'
import "../components/coreCard.css"
const CoreCard = props => {
    return (
        <div className="core-card">
            <div className="core-icon">
                {props.icon}
            </div>
            <h3 className="core-title">{props.title}</h3>
        </div>
    )
}

export default CoreCard