import '../components/footer.css';
import LinkedinIcon from '../components/svg/linkedin';
import InstagramIcon from '../components/svg/instagram';
import MapPinIcon from '../components/svg/mappin';
import PhoneIcon from '../components/svg/phone';
import EnvelopeIcon from '../components/svg/envelope';
import MapEmbed from './mapEmbed';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import CalendlySchedule from './calendlySchedule';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
      <>
      <CalendlySchedule />
      <div className="map_wrapper">
        <MapEmbed 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2690.7778778906077!2d78.39280124862549!3d17.49153162889439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x81590ec7359ee666!2sManjeera+Majestic+Commercial!5e0!3m2!1sen!2sin!4v1562307619248!5m2!1sen!2sin" 
          width="100%" 
          height="300px" 
        />
        <MapEmbed 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.6631770407785!2d-116.99204968548942!3d32.5084305049401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d948014affffff%3A0x22d96c137cafdf74!2sBPO%20Solutions%20Group!5e0!3m2!1sen!2sin!4v1623335449440!5m2!1sen!2sin" 
          width="100%" 
          height="300px" 
        />
      </div>
      <footer className="footer">
        <div className="info_wrps">
          <div className="container">
            <div className="info_row">
              <div className="info_txtb">
                  <span className="info_icon"><MapPinIcon width="24" height="24" /></span> 
                  Manjeera Majestic Commercial, Kukatpally, Hyderabad, Telangana 500072.
              </div>
              <div className="info_txtb">
                <span className="info_icon"><EnvelopeIcon width="24" height="24" /></span>
                <Link 
                  to="#"
                  onClick={(e) => {
                      window.location.href = 'mailto:info@mahasos.com';
                      e.preventDefault();
                  }}
                >info@mahasos.com</Link>
              </div>
              <div className="socialicon">
                <Link 
                  target={"_blank"} 
                  to="#"
                  onClick={() => openInNewTab('https://www.linkedin.com/in/mahasos1/')}
                  className="sm_icon"
                >
                  <LinkedinIcon width="24" height="24" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <div className="container">
            Copyright © 2015-{currentYear}, Mahasos Info Private Ltd. All Rights Reserved
          </div>
        </div>
      </footer>
      </>
  )
  
}

export default Footer;