import React from 'react'
import Layout from '../components/layout'
import ServiceOPCard from '../components/serviceOPCard'

const Sixjuly = props => {
    return (
        <Layout>
            <section className="service_section">
                <div className="container">
                    <div className="row">
                        <div className="w_full">
                            <h1 className="page_title textCenter">SixJuly Services</h1>
                        </div>          
                        <div className="service-op-group">
                            <ServiceOPCard
                                title="UI/UX Design"
                                desc="Revolutionizing user experiences with expert UI/UX services, optimizing interactions for engagement and efficiency, setting industry standards."
                                lists={[
                                    "User Experience Strategy", 
                                    "Web & Mobile App Design", 
                                    "Design System", 
                                    "Visual Design", 
                                    "Interaction Design", 
                                    "Prototyping", 
                                    "User Interface Design", 
                                    "Design Audit", 
                                    "Reports/ Dashboards Design"
                                ]}
                            />
                            <ServiceOPCard
                                title="Graphic Design"
                                desc="Elevating brands through strategic design, crafting compelling identities that resonate, setting benchmarks for market presence."
                                lists={[
                                    "Logo", "Visual Identity", 
                                    "Brand Guidelines", 
                                    "Asset Creation", 
                                    "Printable Assets", 
                                    "Redesign",
                                ]}
                            />
                            <ServiceOPCard
                                title="Brand identity"
                                desc="Transforming visions into stunning visuals, our graphic design services ignite brands, captivate audiences, and inspire action."
                                lists={[
                                    "Web & Mobile App Banners",
                                    "Logo Design",
                                    "Social Media Assets",
                                    "Marketing collaterals",
                                    "Brand guidelines",
                                    "Presentation",
                                    "Illustrations",
                                    "Posters",
                                    "Flyer Design",
                                    "Brochure",
                                ]}
                            />
                            <ServiceOPCard
                                title="Product Design"
                                desc="Innovating user-centric product designs, seamlessly blending form and function to redefine industry standards and elevate user experiences."
                                lists={[
                                    "Web & Mobile app design",
                                    "Interaction Design",
                                    "Design System",
                                    "UX Testing",
                                    "UX Research",
                                    "Prototyping",
                                    "Redesign",
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Sixjuly
