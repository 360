import React from "react"
import './serviceOPCard.css'
import Buttons from "./button"
import { useNavigate } from 'react-router-dom';

const ServiceOPCard = props => {
    const navigate = useNavigate();
    return (
        <div className="sopcardbox">
            <div className="sopcard">
                <div className="sop-top">
                    <h5 className="sopcard-title">{props.title}</h5>
                    <p className="sopcard-desc">{props.desc}</p>
                    <ul className="sopcard-lists">
                        {props.lists.map(function(d, idx){
                            return (
                                <li key={idx}>
                                    {d}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="sop-bottom">
                    <Buttons 
                        title="Let's work together" 
                        type="button" 
                        onClick={() => navigate('/contactus')}
                    />
                </div>
            </div>
        </div>
    )
}

export default ServiceOPCard