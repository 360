import React, { Component } from "react";
import Slider from "react-slick";
import '../components/productsSlider.css'
import ChevronRight from './svg/chevronRight'
import ChevronLeft from "./svg/chevronLeft";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: "1" }}
      onClick={onClick}
    >
      <ChevronRight width="32" height="32" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: "1" }}
      onClick={onClick}
    >
      <ChevronLeft width="32" height="32" />
    </div>
  );
}

export default class ProductsSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="psslide">
            <div className="pscard">
              <h3 className="pscard_title">01</h3>
              <p className="pscard_description">Inventory Management <br /> for Multi-channel eCommerce selling</p>
              <Link to="/ourProduct/orderManagement" className="button">Click Here To Know More</Link>
            </div>
          </div>
          <div className="psslide">
            <div className="pscard">
              <h3 className="pscard_title">02</h3>
              <p className="pscard_description">Order Management <br /> for Multi-channel eCommerce selling</p>
              <Link to="/ourProduct/orderManagement" className="button">Click Here To Know More</Link>
            </div>
          </div>
          <div className="psslide">
            <div className="pscard">
              <h3 className="pscard_title">03</h3>
              <p className="pscard_description">Integrate with multiple marketplaces</p>
              <Link to="/ourProduct/integrate" className="button">Click Here To Know More</Link>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}