import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const DropdownMenu = props => {
    const { pathname } = useLocation();
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    return (
        <>
        <div 
            className={`${props.className} dropdown-wrapper`}
        >
            <div className="dropdown-trigger" onClick={()=>setIsDropdownShow( state => !state)}>{props.title}</div>
            {isDropdownShow && (
                <div className="dropdown-content">
                    <ul>
                        {props.options.map(function(d, idx){
                            return (<li {...props} key={idx} className={pathname === d.link ? 'active' : ''}><Link to={d.link}>{d.name}</Link></li>)
                        })}
                    </ul>
                </div>
            )}
            {isDropdownShow && (
                <div className="dropdown-backdrop" onClick={()=>setIsDropdownShow(false)}></div>
            )}
        </div>
        </>
    )
}

export default DropdownMenu

DropdownMenu.defaultProps = {
	title: "Dropdown",
    options: [
        {
            name:"Something 1",
            link: "/"
        },
        {
            name:"Something 2",
            link: "/"
        },
        {
            name:"Something 3",
            link: "/"
        },
    ]
};
