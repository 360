import React from "react"
import '../components/teamCard.css'

const TeamCard = props => {
    return (
        <div className="team_box">
            <div className="team_img">
                <img src={props.src} alt={props.name} className="img-fluid"  />
            </div>
            <div className="team_text">
                <h3 className="team_title">{props.name}</h3>
                <span className="team_designer">{props.title}</span>
            </div>
        </div>
    )
}

export default TeamCard
