import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import DropdownMenu from "./dropDown";

const BPOOptions = [
    {
        name: "BPO",
        link: "/bpo"
    },
    {
        name: "B2B Lead Generation",
        link: "/b2bleadgeneration"
    },
]

const Options = [
    {
        name: "Inventory Management",
        link: "/ourProduct/inventoryManagement"
    },
    {
        name: "Order Management",
        link: "/ourProduct/orderManagement"
    },
    {
        name: "Integrate",
        link: "/ourProduct/integrate"
    }
]

const MenuNav = props => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const { pathname } = useLocation();
    const bpoPaths = ["/bpo", "/b2bleadgeneration" ];
    const bpoClassName = bpoPaths.includes(pathname)  ? "dropdown-active" : '';
    const ourProductsPaths = ["/ourProduct/inventoryManagement", "/ourProduct/orderManagement", "/ourProduct/integrate", "/ourProduct/artificialIntelligence/" ]
    const ourProductsClassName = ourProductsPaths.includes(pathname) ? "dropdown-active" : '';
    return(
        <nav className={isTabletOrMobile ? 'nav nav-expand' : 'nav'}>
            <Link to="/" className={pathname === "/" ? 'active' : ''}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
                </svg>
            </Link>
            <Link to="/about" className={pathname === "/about" ? 'active' : ''}>
                About us
            </Link>
            <Link to="/team" className={pathname === "/team" ? 'active' : ''}>
                Our Team
            </Link>
            <Link to="/portfolio" className={pathname === "/portfolio" ? 'active' : ''}>
                Portfolio
            </Link>
            <Link to="/services" className={pathname === "/services" ? 'active' : ''}>
                Services
            </Link>
            <Link to="/artificialIntelligence" className={pathname === "/artificialIntelligence" ? 'active' : ''}>
                AI/ML
            </Link>
            <DropdownMenu
                title="BPO"
                options={BPOOptions}
                className={bpoClassName}
            />
            <Link to="/jobs" className={pathname === "/jobs" ? 'active' : ''}>
                Jobs
            </Link>
            <DropdownMenu
                title="Our Products"
                options={Options}
                className={ourProductsClassName}
            />
            <Link to="/contactus" className={pathname === "/contactus" ? 'active' : ''}>
                Contact
            </Link>
        </nav>
    )
}

export default MenuNav; 