import React from "react"

const HomeFront = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1000px" height="1000px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="161.3" cy="209.8" r="20.6" />
            <circle fill="#2a78ff" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="362.3" cy="667.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="670.3" cy="736.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="737.3" cy="463.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="341.3" cy="381.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="847.3" cy="242.8" r="20.6" />
            <circle fill="#2a78ff" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="943.3" cy="471.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="680.3" cy="93.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="217.3" cy="848.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="528.3" cy="945.8" r="20.6" />
            <circle fill="#2a78ff" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="820.3" cy="810.8" r="20.6" />
            <circle fill="#2a78ff" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="94.3" cy="520.8" r="20.6" />
            <circle fill="#2a78ff" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="528.3" cy="267.8" r="20.6" />
            <circle fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" cx="395.3" cy="93.8" r="20.6" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="155.2" y1="229.5" x2="100.2" y2="501.1" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="181.9" y1="209.8" x2="507.7" y2="267.8" />
            <line fill="none" stroke="#2a78ff" strokeWidth="22" strokeMiterlimit="10" x1="416" y1="95" x2="660" y2="95" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="405.6" y1="114.4" x2="512.7" y2="247.2" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="700.9" y1="106.3" x2="831.6" y2="229.5" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="857.4" y1="263.4" x2="931.5" y2="451.2" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="917.4" y1="471.8" x2="757.9" y2="463.8" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="512.7" y1="281.3" x2="359" y2="371.3" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="329" y1="365.3" x2="174.4" y2="225.7" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="112.5" y1="530.3" x2="344.8" y2="657" />
            <line fill="none" stroke="#2a78ff" strokeWidth="22" strokeMiterlimit="10" x1="382.9" y1="657" x2="718.3" y2="471.8" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="361" y1="387.8" x2="716.7" y2="463.8" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="742.1" y1="484.4" x2="820.3" y2="790.2" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="228.7" y1="831.7" x2="348.1" y2="682.7" />
            <line fill="none" stroke="#2a78ff" strokeWidth="22" strokeMiterlimit="10" x1="374.7" y1="684.2" x2="512.7" y2="932.4" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="538.8" y1="928.1" x2="659.7" y2="754.5" />
            <line fill="none" stroke="#2a78ff" strokeWidth="22" strokeMiterlimit="10" x1="731.5" y1="443.2" x2="680.3" y2="114.4" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="341.3" y1="402.4" x2="362.3" y2="647.2" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="233.3" y1="861.8" x2="507.7" y2="945.8" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="670.3" y1="716.2" x2="351.8" y2="399.5" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="548.9" y1="267.8" x2="831.6" y2="256.2" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="684.9" y1="722.3" x2="931.5" y2="488.7" />
            <line fill="none" stroke="#2a78ff" strokeWidth="10" strokeMiterlimit="10" x1="548.9" y1="945.8" x2="804.3" y2="823.8" />
        </svg>
    )
}

export default HomeFront
