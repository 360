import React from "react"
import '../components/cardSecondary.css'
import Row from "./row"
import LocationIcons from '../assets/images/location_icons.png'

const CardSecondaryGroup = props => {
    return(
        <>
            <Row warning>
                {props.content.map(function(d, idx){
                    return (
                        <div className="cardsecondary" key={idx}>
                            <div className="cs_main">
                                <span className="cs_icon">
                                    <img src={d.icon} alt={d.title} className="img-fluid" />
                                </span>
                                <h3>{d.title}</h3>
                                <p>{d.content}</p>
                            </div>
                        </div>
                    )
                })}
            </Row>
        </>
    )
}

export default CardSecondaryGroup

CardSecondaryGroup.defaultProps = {
    content: [
        {
            title:"Something 1",
            icon: LocationIcons,
            content: "test"
        },
        {
            title:"Something 1",
            icon: LocationIcons,
            content: "test"
        },
        {
            title:"Something 1",
            icon: LocationIcons,
            content: "test"
        },
    ]
};
