import React from "react"
import '../components/placeCard.css'
import MapPinIcon from '../components/svg/mappin'
import EnvelopeIcon from '../components/svg/envelope'
import PhoneIcon from '../components/svg/phone'
import { Link } from "react-router-dom"

const PlaceCard = props => {
    return (
        <div className="placecard">
            <img src={props.image} alt={props.title} />
            <div className="placedetail">
                <h3>{props.title}</h3>
                <p>
                    <span className="placeicon">
                        <MapPinIcon width="20" height="20" />
                    </span>
                    {props.address}</p>
                <p>
                    <span className="placeicon">
                        <EnvelopeIcon width="20" height="20" />
                    </span>
                    <Link 
                        to="#"
                        onClick={(e) => {
                            window.location.href = props.mailId;
                            e.preventDefault();
                        }}
                    >{props.mailText}</Link></p>
                <p>
                    <span className="placeicon">
                        <PhoneIcon width="20" height="20" />
                    </span>
                    <Link
                        to="#"
                        onClick={(e) => {
                            window.location.href = props.phoneId;
                            e.preventDefault();
                        }}
                    >
                        {props.phoneText}
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default PlaceCard

PlaceCard.defaultProps = {
    title: "India",
    image: "/aboutus.svg",
    address: "815, 8th Floor, Manjeera Majestic Commercial, Kukatpally, Hyderabad, Telangana 500072",
    mailId: "mailto:info@mahasos.com",
    mailText: "info@mahasos.com",
    phoneId: "tel:+91-916-025-0004",
    phoneText: "+91-916-025-0004",
}