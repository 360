import React from "react"

const ChevronLeft = props => {
    return (
        <svg 
            width={props.width} 
            height={props.height} 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        >
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    )
}

export default ChevronLeft
