import React from "react"
import '../components/wddpCard.css'

const WDDPCard = props => {
    return (
        <div className="wdp_box">
            <div className="wdp_card">
                <span className="wdp_count">{props.count}</span>
                <h3 className="wdp_title">{props.title}</h3>
                <div className="wdp_desc">{props.desc}</div>
            </div>
        </div>
    )
}

export default WDDPCard
