import React from "react"

const HomeBack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1000px" height="1000px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
            <g>
                <polyline fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeliterlimit="10" points="200.7,251 201.7,328.3 279,329.3 279.9,406.6 357.2,407.5 358.2,484.8 435.5,485.8" />
            </g>
            <g>
                <polyline fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="596,131.5 651.3,185.5 706.7,131.5 762,185.5 817.3,131.5 872.7,185.5 928,131.5" />
            </g>
            <rect x="727.5" y="567.5" fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" width="100" height="100" />
            <g>
                <line fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="890.5" y1="328" x2="790.5" y2="428" />
                <line fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="790.5" y1="328" x2="890.5" y2="428" />
            </g>
            <g>
                <line fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="148.5" y1="485" x2="148.5" y2="627" />
                <line fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="78" y1="556.5" x2="219" y2="556.5" />
            </g>
            <polygon fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="332.5,200 332.5,99.5 232,99.5" />
            <polygon fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="558.5,632.8 487.4,561.8 416,633.2" />
            <circle fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="311" cy="807" r="50" />
            <circle fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="562" cy="294" r="50" />
            <g>
                <path fill="none" stroke="#FFFFFF" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M512,816.5c36.6,0,36.6,52,73.2,52c36.6,0,36.6-52,73.2-52c36.6,0,36.6,52,73.3,52c36.6,0,36.6-52,73.3-52" />
            </g>
        </svg>
    )
}

export default HomeBack
