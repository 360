import React from "react"
import '../components/cardSummary.css'
import Row from "./row"
import LocationIcons from '../assets/images/location_icons.png'

const CardSummary = props => {
    return(
        <>
            <Row>
                {props.content.map(function(d, idx){
                    return (
                        <div className="cardsummary" key={idx}>
                            <div className="cs_main">
                                <h3>{d.title}</h3>
                                <p>{d.content}</p>
                            </div>
                        </div>
                    )
                })}
            </Row>
        </>
    )
}

export default CardSummary

CardSummary.defaultProps = {
    content: [
        {
            title:"Something 1",
            icon: LocationIcons,
            content: "test"
        },
        {
            title:"Something 1",
            icon: LocationIcons,
            content: "test"
        },
        {
            title:"Something 1",
            icon: LocationIcons,
            content: "test"
        },
    ]
};
