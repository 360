import React from 'react'
import './calendlySchedule.css';
import ThinkTankMemberImg from '../assets/images/schedule-meeting.svg'
import MeetingCallIcon from '../assets/images/call-meeting-icon.png';
import { PopupButton } from "react-calendly";

const CalendlySchedule = () => {
    return (
        <section className="calendly-section">
            <div className="area calendly-area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
            <div className="container">
                <div className="calendly-wrapper">
                    <div className="calendly-left">
                        <div className="calendly-image">
                            <img src={ThinkTankMemberImg} alt="Think Tank Member" className="img-fluid" />
                            <h3 className="calendly-image-title">Schedule</h3>
                        </div>
                    </div>
                    <div className="calendly-right">
                        <div className="calendly-content">
                            <h2 className="text-primary">Request a 30 Minute Meeting <br /> With Think Tank Member</h2>
                            <p>Confirm your time zone and <br /> select a time to submit your request</p>
                            <div className="calendly-meeting-call-icon">
                                <img src={MeetingCallIcon} alt="Think Tank Member" className="img-fluid" />
                            </div>
                            <PopupButton
                                url="https://calendly.com/mahasos/30min?hide_gdpr_banner=1&month=2024-08"
                                /*
                                * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                */
                                rootElement={document.getElementById("root")}
                                text="Let's Have a Call"
                                className="btn"
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default CalendlySchedule
